
    import React from 'react';

    // const ApiURl = "http://192.168.1.9/react-projetcs/ecommerce_main/newEcomm/src/ajax";
    const ApiURl = "https://bhe.algo2botsinfotech.com/ajax";










    export default   ApiURl ;