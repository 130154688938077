


const imgLocation = "https://bhe.algo2botsinfotech.com/ajax/images";
// const ApiURl = "https://bhe.algo2botsinfotech.com/ajax";










export default   imgLocation ;