import React, { useEffect, useState } from "react";
import Logo from "../images/bhatia-emporium.jpg";
import { useParams } from "react-router-dom";
import $ from "jquery";
import ApiURl from "../controllers/Api";

const Receipt = () => {
  const { order_id } = useParams();

  const user_id = localStorage.getItem("user_id");


  const  [orderData,setOrdersData] = useState([]);



  const data = { order_id:order_id, user_id:user_id  };

  const [total,setTotalAmount] = useState(10);



  const items = [
    { name: "Kitchen Apron", quantity: 2, price: 120.0 },
    { name: "Kitchen Check Apron", quantity: 1, price: 95.0 },
    { name: "Fridge Top Cover", quantity: 5, price: 75.0 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = { order_id: order_id, user_id: user_id }; // Example data object

        // Await the $.getJSON request
        const response = await $.getJSON(`${ApiURl}/getOrders.php`, data);

        console.log(response.data); // Log the fetched data
        setOrdersData(response.data); // Set the order data state

        if (response.data && response.data.length > 0) {
          setTotalAmount(response.data[0].total_amount); // Set the total amount from the first order
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async fetchData function

  }, []); // Empty dependency array ensures this runs once when the component mounts


  const subtotal = Number(total) 
  const tax = subtotal * 0.0; // 10% tax

  

  

  return (
    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 my-10 mx-10 border-t">
      <h1 className="w-28 mx-auto border">
        <img src={Logo} alt="My Image" sty />
      </h1>
      <p className="text-center text-gray-600">Thank you for your purchase!</p>

      <div className="mt-4">
        <h2 className="text-lg font-semibold">Transaction Details</h2>
        <div className="border-t border-gray-300 pt-2 mb-2">
          <p className="flex justify-between">
            <span>Date:</span>
            <span>{new Date().toLocaleDateString()}</span>
          </p>
          <p className="flex justify-between">
            <span>Order ID:</span>
            <span>#6543</span>
          </p>
        </div>
        <div className=" border-gray-300 pt-2">
          <p className="flex justify-between text-sm mb-2">
            <span className="font-semibold">Order Status : </span> Created
            Successfully
          </p>
          <p className="flex justify-between text-sm mb-2">
            <span className="font-semibold">Customer Name : </span> Manish Singh
            Negi
          </p>
          <p className="flex justify-between text-sm mb-2">
            <span className="font-semibold">Customer Mobile Number : </span>{" "}
            8448789766
          </p>
          <p className="flex justify-between text-sm mb-2">
            <span className="font-semibold">Customer Address : </span> 139,
            Sathnagar, Palam Gao
          </p>
          <p className="flex justify-between text-sm">
            <span className="font-semibold">Payment Type : </span> Cash On
            Delivery
          </p>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-lg font-semibold">Items</h2>
        <div className="border-t border-gray-300 pt-2">
          {orderData.map((item, index) => (
            <div key={index} className="flex justify-between py-2">
              <span>
                {item.name} (x{item.quantity})
              </span>
              <span>&#x20b9;{(item.price * item.quantity).toFixed(2)}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 border-t border-gray-300 mb-2">
        <div className="flex justify-between ">
          <span>Subtotal:</span>
          <span>&#x20b9;{subtotal.toFixed(2)}</span>
        </div>
        <div className="flex justify-between">
          <span>Tax (0%):</span>
          <span>&#x20b9;{tax.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-bold mt-2">
          <span>Total:</span>
          <span>&#x20b9;{Number(tax)+Number(total)}.00</span>
        </div>
      </div>

      <div className="mt-6 text-center mt-3">
        <p className="text-gray-600">We hope to see you again!</p>
        <p className="text-sm text-gray-500">
          For any inquiries, contact us at care@bhatiaemporium.com
        </p>
      </div>
    </div>
  );
};

export default Receipt;
