import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import { Link, useNavigate } from "react-router-dom";
import ApiURl from "../controllers/Api";
import $ from 'jquery'

const OrderSection = ({data}) => {


  

  console.log(data);



  const navigate = useNavigate();

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mt-5 mx-3 md:mx-6">
      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold mb-6">My Orders </h2>
        <Filter />
      </div>

      <div className="space-y-6">
        {data.map((order) => (
          <div key={order.order_id} className="bg-gray-100 p-4 rounded-lg shadow-md">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6 items-center">
              {/* Order ID */}
              <div>
                <h4 className="font-semibold text-gray-700">
                  Order #{order.order_id}
                </h4>
                <p className="text-gray-600">Date: {order.order_date}</p>
              </div>

              {/* Order Total */}
              <div>
                <h4 className="font-semibold text-gray-700">States</h4>
                <p className="text-gray-600">{order.state}</p>
              </div>
              <div>
                <h4 className="font-semibold text-gray-700">Phone</h4>
                <p className="text-gray-600">{order.phone_number}</p>
              </div>

              {/* Order Status */}
              <div>
                <h4 className="font-semibold text-gray-700">Status</h4>
                <p
                  className={`text-${
                    order.status === "Delivered" ? "green" : "yellow"
                  }-600`}
                >
                  {order.status}
                </p>
              </div>

              {/* Order Total */}
              <div>
                <h4 className="font-semibold text-gray-700">Total</h4>
                <p className="text-gray-600">{order.total_amount  }</p>
              </div>

              {/* View Details Button */}
              <div className="text-center flex md:text-left border">
                <button className="bg-blue-500 whitespace-nowrap text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600">
                  View Details
                </button>

                <button
                onClick={()=>{
                  navigate(`/cart/checkout/receipt/${order.order_id}`)

                }}
                 className="bg-blue-500 text-white whitespace-nowrap text-sm px-4 py-2 rounded-md hover:bg-blue-600 ml-2">
                  View Receipt
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderSection;
